import React, {useState, useEffect} from 'react'
import ingApp from '../Assets/ingapp.svg'
import ingcyber from '../Assets/ingCyber.svg'
import incloud from '../Assets/ingCloud.svg'
import inmicro from '../Assets/ingMicro.svg'
import dpifront from '../Assets/dpifront.jpeg'

const Services = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
        window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleScroll = () => {
        const element = document.querySelector(".AnimationDiv");
        const position = element.getBoundingClientRect();

        if (position.top < window.innerHeight) {
        setIsVisible(true);
        }
    };
  return (
    <>
        <div id='Services' className={`Services pousserServices ${isVisible ? "AnimationDiv" : ""}`}>
        <div className="tiltleServ pousserTitle">
            <span>Nos Services</span>
        </div>
        <div className="descrServ">
            <p>Notre expertise dans les domaines ci-dessous nous permet
            de créer des solutions digitales innovantes pour vous aider à atteindre vos objectifs.</p>
        </div>
        <div className="ListServices">
            <div className="servicesEle">
                <div className="iconService">
                    <img src={ingApp} alt="d’application mobile, web et UX."  />
                </div>
                <div className="titleservices">
                    <span>Ingénierie du développement 
                    d’application mobile, web et UX.</span>
                </div>

            </div>
            <div className="servicesEle">
                <div className="iconService">
                    <img src={ingcyber} alt="d’application mobile, web et UX."  />
                </div>
                <div className="titleservices">
                    <span>Ingénierie <br />
                    de la cybersécurité 
                    </span>
                </div>

            </div>
            <div className="servicesEle">
                <div className="iconService">
                    <img src={incloud} alt="d’application mobile, web et UX."  />
                </div>
                <div className="titleservices">
                    <span>Ingénierie des <br />
                    infrastructures cloud</span>
                </div>

            </div>
            <div className="servicesEle">
                <div className="iconService">
                    <img src={inmicro} alt="d’application mobile, web et UX."  />
                </div>
                <div className="titleservices">
                    <span>Ingénierie logicielle <br /> et microservices</span>
                </div>

            </div>
        </div>
        </div>

        <div className="servicedeploie  margin-to">
        <div className={`Services solutionsServices ${isVisible ? "AnimationDiv" : ""}`}>
        <div className="tiltleServ">
            <span>Nos Solutions déja déployées</span>
        </div>
        <div className="descrServ">
            <p>sit amet consectetur adipisicing elit.
            Consequatur mollitia officiis cum accusamus repellendus tempora recusandae</p>
        </div>
        <div className="ListSolutions ">
            <div className="servicesElement">
                <div className="SolutionImage">
                    <img src={dpifront} alt="d’application mobile, web et UX."  />
                    
                
                </div>
                
                <div className="titlesolution">
                    <div className="titilesolu">
                    <span>Logiciel DPI  <br />
                        <span>(Dossier patient informatisé)</span></span>
                </div>
                    <p className='textsolution'>Le Dossier Patient Informatisé (DPI) est une solution informatique qui permet de centraliser les informations médicales d'un patient dans un système électronique. Cette solution permet aux professionnels de santé d'accéder facilement aux informations médicales du patient, d'optimiser la prise en charge et de faciliter la coordination entre les différents intervenants de la chaîne de soins.</p>
                </div>

            </div>
            <div className="servicesElement">
                <div className="SolutionImage">
                    <img src={dpifront} alt="d’application mobile, web et UX."  />
                    
                
                </div>
                
                <div className="titlesolution">
                    <div className="titilesolu">
                    <span>Logiciel DPI <br />
                        <span>(Dossier patient informatisé)</span></span>
                </div>
                    <p className='textsolution'>Le Dossier Patient Informatisé (DPI) est une solution informatique qui permet de centraliser les informations médicales d'un patient dans un système électronique. Cette solution permet aux professionnels de santé d'accéder facilement aux informations médicales du patient, d'optimiser la prise en charge et de faciliter la coordination entre les différents intervenants de la chaîne de soins.</p>
                </div>

            </div>
            <div className="servicesElement">
                <div className="SolutionImage">
                    <img src={dpifront} alt="d’application mobile, web et UX."  />
                    
                
                </div>
                
                <div className="titlesolution">
                    <div className="titilesolu">
                    <span>Logiciel DPI <br />
                        <span>(Dossier patient informatisé)</span></span>
                </div>
                    <p className='textsolution'>Le Dossier Patient Informatisé (DPI) est une solution informatique qui permet de centraliser les informations médicales d'un patient dans un système électronique. Cette solution permet aux professionnels de santé d'accéder facilement aux informations médicales du patient, d'optimiser la prise en charge et de faciliter la coordination entre les différents intervenants de la chaîne de soins.</p>
                </div>

            </div>
            
            
            
            
            
        </div>
        </div>
        </div>
    </>
    
  )
}

export default Services
