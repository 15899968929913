import React, {useState} from 'react'
import logotirblanc from '../Assets/logoblanc.svg'
import DehazeIcon from '@mui/icons-material/Dehaze';
import ClearIcon from '@mui/icons-material/Clear';

const NavBar = () => {

  const [isActive, setActive] = useState("false");
  const NavResponsive =()=>{
    setActive(!isActive);

    console.log(isActive)
  }
  const NavClean =()=>{
    setActive(!isActive);

    console.log(isActive)
  }
  return (
    <div className='NavBar flexdis'>
      <div className="LogoNavBar responsiveNab">
        <div className="logo">
          <img src={logotirblanc} alt="logo TIR" />
        </div>
        <div className="NavBarIcon">
          <DehazeIcon className={`${isActive ? '' : 'cacheCroix1'}`} onClick={NavResponsive} />
          <ClearIcon className={`${isActive ? 'cacheCroix2' : ''}`} onClick={NavClean}/>
        </div>
      </div>
      
      <div className={`NavList AnimationDiv ${isActive? 'cacherNavb' : ''}`} >
        <ul className='flexdis ListNav ResponsiveBack'>
          <li><a href="#Accueil"> Accueil</a></li>
          <li><a href="#Propos">A propos</a> </li>
          <li> <a href="#Services">Nos services</a></li>
          <li> <a href="#Pourquoi">Pourquoi nous ?</a> </li>
        </ul>
      </div>
      <div className={`action-btn AnimationDiv ${isActive? 'cacherNavb AnimationDiv' : ''} cacheForcer`}>
        <a href="#Contacts"><button className='Contact-nous '>Contactez-nous</button></a>
      </div>
      
    </div>
  )
}

export default NavBar
