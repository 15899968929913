import React from 'react'
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import patnericon from '../Assets/patnericon.svg'
import brainicon from '../Assets/brainicon.svg'
import experienceicon from '../Assets/experienceicon.svg'

const Pourquoi = () => {
  return (
    <div id='Pourquoi' className='Pourquoi AnimationDiv'>
        <div className="Whytitle">
            <div className="tiltleServ">
                <span>Pourquoi nous choisir ?</span>
            </div>
            <div className="descrServ">
                <p>Il y a plusieurs raisons pour lesquelles votre entreprise 
                pourrait choisir la notre, pour vos projets digitaux. Voici quelques-unes des raisons les plus importantes</p>
            
            </div>
        </div>
        <div className="textexplication">
            <div className="textexpli">
                <div className="raison">
                    <div className="icontext"><CheckBoxIcon className='iconsucces' /></div>
                    <div>
                        <span>Nous sommes spécialisée dans plusieurs domaines clés du digital permettant de fournir vous des solutions sur mesure pour répondre a vos besoins spécifiques. </span>
                    </div>
                </div>
                <div className="raison">
                    <div className="icontext"><CheckBoxIcon className='iconsucces' /></div>
                    <div>
                        <span>Nous avons bâti sa réputation sur la qualité de ses services. L'entreprise est connue pour fournir des solutions de haute qualité qui répondent aux normes les plus élevées de l'industrie.</span>
                    </div>
                </div>
                <div className="raison">
                    <div className="icontext"><CheckBoxIcon className='iconsucces' /></div>
                    <div>
                        <span>Nous utilissons des technologies innovantes pour développer des solutions digitales de pointe.</span>
                    </div>
                </div>
                <div className="raison">
                    <div className="icontext"><CheckBoxIcon className='iconsucces' /></div>
                    <div>
                        <span>Nous travaillons avec des partenaires prestigieux tels que la Banque Mondiale et le Gouvernement Ivoirien.</span>
                    </div>
                </div>
                
            </div>
            <div className="iconwhy">
                <div className="expertise">
                    <div className="iconexpert ">
                        <StarBorderIcon className='iconExpert' />
                    </div>
                    <div className="titleexpert">
                        <span>Expertise</span>
                    </div>
                </div>
                <div className="expertise ">
                    <div className="backvert">
                        <img src={brainicon} alt="Innovation"  />
                        
                    </div>
                    <div className="titleexpert">
                        <span>Innovation</span>
                    </div>
                </div>
                <div className="expertise">
                    <div className="backvert">
                        <img className='' src={experienceicon} alt="Expérience icon" />
                    </div>
                    <div className="titleexpert">
                        <span>Expérience</span>
                    </div>
                </div>
                <div className="expertise">
                    <div className="iconexpert">
                        <img src={patnericon} alt="partenaires"  />
                    </div>
                    <div className="titleexpert">
                        <span>Partenaires <br /> prestigieux</span>
                    </div>
                </div>
            </div>
        </div>
        <div className="btn-action">
            <a href="#Contacts"><button className='contactNous'>Contactez-nous</button></a>
        </div>
        
    </div>
  )
}

export default Pourquoi
