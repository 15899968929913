import React,{useEffect, useState} from 'react'
import Equipes from '../Assets/IMAGEEquipe.png'

const NotreEquipe = () => {

  const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
        window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleScroll = () => {
        const element = document.querySelector(".AnimationDiv");
        const position = element.getBoundingClientRect();

        if (position.top < window.innerHeight) {
        setIsVisible(true);
        }
    };

  return (
    <div id='NotreEquipe' className={`NotreEquipe ${isVisible ? "AnimationDiv" : ""}`}>
      <div className="Whytitle">
            <div className="tiltleServ">
                <span>Notre équipe</span>
            </div>
            <div className="descrServ">
                <p>Notre team est composée d'une équipe dynamique et expérimentée de professionnels du digital, qui travaillent ensemble pour offrir les meilleurs services à nos clients. Chaque membre de notre équipe apporte des compétences uniques et une expertise spécifique dans leur domaine d'activité, ce qui nous permet de fournir des solutions sur mesure et innovantes pour répondre aux besoins de nos clients.</p>
            </div>
      </div>
      <div className="imageequipe">
        <img src={Equipes} alt="Equipe TIR"/>
      </div>
    </div>
  )
}

export default NotreEquipe
