import React from 'react'
import CopyrightOutlinedIcon from '@mui/icons-material/CopyrightOutlined';

const Footer = () => {
  return (
    <div className='Footer'>
        <div className="Iconcopy">
            <CopyrightOutlinedIcon fontSize='12' />   
        </div>
        <div className="textCopy">
            <span>  2023  TIR | Technology Innovation Revolutionner -  All Rights Reserved. </span>
        </div>
      
    </div>
  )
}

export default Footer
