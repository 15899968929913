import React, {useEffect, useState} from 'react'

import AmoirieCIV from '../Assets/ArmoirieEle.png'
import BanqueM  from '../Assets/BanqueM.jpeg'
import SanteL from '../Assets/Sante.jpeg'
import DIISLOGO from '../Assets/DIIS.jpeg'
import UCPlogo from '../Assets/UCP.png'


const Patner = () => {

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const element = document.querySelector(".AnimationDiv");
    const position = element.getBoundingClientRect();

    if (position.top < window.innerHeight) {
      setIsVisible(true);
    }
  };

  return (
    <div id='Patner' className={`Partenaire ${isVisible ? "AnimationDiv" : ""}`}>
      <div className="imgPartner">
        <div className="patner">
            <div className="img1">
              <a href="https://www.gouv.ci/Main2.php" target="_blank" rel="noopener noreferrer">
                <img src={AmoirieCIV} alt="efd" />
              </a>
              
              </div>
            <div className="img1">
              <a href="https://www.banquemondiale.org/fr/home" target="_blank" rel="noopener noreferrer">
                <img src={BanqueM} alt="efd" />
              </a>
              
              </div>
            <div className="img1">
              <a href="https://vaccination.ci/" target="_blank" rel="noopener noreferrer">
                <img src={SanteL} alt="efd" />
                </a>
              
              </div>
            <div className="img1">
              <a href="https://www.snisdiis.com/" target="_blank" rel="noopener noreferrer">
                <img src={DIISLOGO} alt="efd" />
              </a>
              
              </div>
            <div className="img1">
                <a href="https://www.ucp-fm.com/" target="_blank" rel="noopener noreferrer">
                  <img src={UCPlogo} alt="efd" />
                </a>
              
              </div>
            
            
            
        </div>
        
    

      </div>
    </div>
  )
}

export default Patner
