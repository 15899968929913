import React from 'react'
import Patner from './Patner';
import Propos from './Propos';
import Services from './Services';
import Pourquoi from './Pourquoi';
import NotreEquipe from './NotreEquipe';
import Temoignages from './Temoignages';
import Contacts from './Contacts';
import Footer from '../Footer/Footer';
import ScrollButton from './Scrollbtn';

const BodyComponents = () => {
  return (
    <>
        <ScrollButton className="AnimationDiv" />
        <Patner />
        <Propos />
        <Services />
        <Pourquoi />
        <NotreEquipe />
        <Temoignages />
        <Contacts />
        <Footer />
    </>
  )
}

export default BodyComponents
